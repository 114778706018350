import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBBox, MDBCarousel, MDBCarouselInner, MDBCarouselItem } from "mdbreact"
import Layout from "../components/TLCLayout"
import ppfImage1 from "../images/services/film/ppf-1.jpg"
import ppfImage2 from "../images/services/film/ppf-2.jpg"
import ppfImage3 from "../images/services/film/ppf-3.jpg"

var iconStyle = {
    fontSize: '80px'
}

var priceText = {
    fontWeight: '800'
}

var calloutStyle = {
    marginTop: '2em'
}

var imageStyle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '3em',
    marginTop: '3em'
}

const PaintProtectionFilmServicesPage = () => (
    <Layout heroTitle="Services" heroImageUrl="./images/hero-dark.jpg">
        <MDBContainer style={{paddingTop: '100px', paddingBottom: '100px'}}>          
            <MDBRow>
                <MDBCol>
                    <h3>Paint Protection Film</h3>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol><img style={imageStyle} src={ppfImage1} width="350px"/></MDBCol>
                <MDBCol><img style={imageStyle} src={ppfImage2} width="350px"/></MDBCol>
                <MDBCol><img style={imageStyle} src={ppfImage3} width="350px"/></MDBCol>
            </MDBRow>              
            <MDBRow>
                <MDBCol>
                    <p>Paint Protection Film, commonly known as clear bra,  is essential in protecting your vehicle’s finish from chips, pitting, and scratches.  At TLC Auto Detail we custom tailor our <span style={{fontWeight: '700'}}>SunTek Ultra PPF</span> to fit various parts of your vehicle providing invisible protection for your finish.</p>
                    <p>Our paint protection film installs are fully custom with edges wrapped wherever possible for a finish that not only looks great, but will last for many years.  We are proud to offer two products from SunTek Films; <span style={{fontWeight: '700'}}>SunTek Ultra PPF</span> and <span style={{fontWeight: '700'}}>SunTek Reaction PPF</span>.  <span style={{fontWeight: '700'}}>SunTek UItra PPF</span> is an industry leading film that is over 8mils thick and features a self healing top coat.  It carries a 10 year manufacturer warranty and is available in glossy or matte finishes.  <span style={{fontWeight: '700'}}>SunTek Reaction PPF</span> takes the performance and looks of Ultra a step further.  This film offers the best gloss and clarity on the market, increased scratch resistance and self healing qualities, and an incredibly stain resistant top coat.</p>
                    <p>Benefits of <span style={{fontWeight: '700'}}>Suntek Paint Protection Films</span>:</p>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md="2"></MDBCol>
                <MDBCol md="8">
                    <MDBRow style={calloutStyle}>
                        <MDBCol md="2">
                            <MDBBox display="flex" justifyContent="center">
                                <i className="fas fa-tint" style={iconStyle}></i>
                            </MDBBox>
                        </MDBCol>
                        <MDBCol md="10">
                            <h4>Lasting Resistance</h4>
                            <p>Top coats increase stain resistance and product longevity by limiting water and dirt accumulation on the film's surface.</p>
                        </MDBCol>
                    </MDBRow>   
                    <MDBRow style={calloutStyle}>
                        <MDBCol md="2">
                            <MDBBox display="flex" justifyContent="center">
                                <i className="fas fa-dumbbell" style={iconStyle}></i>
                            </MDBBox>
                        </MDBCol>
                        <MDBCol md="10">
                            <h4>Extra Endurance</h4>
                            <p>Automotive finishes stay fresh and flawless longer with a film that helps offer the most power to shield against damage caused by rocks, salt, insects, and other road debris.</p>
                        </MDBCol>
                    </MDBRow> 
                    <MDBRow style={calloutStyle}>
                        <MDBCol md="2">
                            <MDBBox display="flex" justifyContent="center">
                                <i className="fas fa-band-aid" style={iconStyle}></i>
                            </MDBBox>
                        </MDBCol>
                        <MDBCol md="10">
                            <h4>Self-Healing</h4>
                            <p>Repairing scratches is almost effortless; film uses heat from a vehicle’s engine or the sun to heal.</p>
                        </MDBCol>
                    </MDBRow>    
                    <MDBRow style={calloutStyle}>
                        <MDBCol md="2">
                            <MDBBox display="flex" justifyContent="center">
                                <i className="fas fa-shield-alt" style={iconStyle}></i>
                            </MDBBox>
                        </MDBCol>
                        <MDBCol md="10">
                            <h4>Great Looks</h4>
                            <p>Gloss and matte finished with outstanding optical clarity makes film nearly impossible to detect once installed.</p>
                        </MDBCol>
                    </MDBRow>      
                    <MDBRow style={calloutStyle}>
                        <MDBCol md="2">
                            <MDBBox display="flex" justifyContent="center">
                                <i className="fas fa-award" style={iconStyle}></i>
                            </MDBBox>
                        </MDBCol>
                        <MDBCol md="10">
                            <h4>10-Year Limited Warranty</h4>
                            <p>The manufacturer warrants professionally sold and applied SunTek® Paint Protection Film Ultra series against cracking, bubbling or yellowing for a period of ten years from the date of purchase of the Product. This 10-year limited warranty applies only to SunTek Paint Protection Film Ultra and Reaction series.</p>
                        </MDBCol>
                    </MDBRow>                                                                                                    
                </MDBCol>
                <MDBCol md="2"></MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    <p>We offer several packages, or we can custom tailor a package to fit your needs perfectly.</p>
                    <p>
                        Partial Front End - Headlights, Fog Lights, Full Front Bumper, Partial Hood, Partial Fenders, and Mirrors - <span style={priceText}>Starting at $1,000</span><br />
                        Full Front End - Headlights, Fog Lights, Full Front Bumper, Full Hood, Full Fenders, and Mirrors - <span style={priceText}>Starting at $1,900</span><br />
                        Track Package - Headlights, Fog Lights, Full Front Bumper, Full Hood, Full Fenders, Mirrors, Rocker Panels, Pillars, and Leading Edge of Roof - <span style={priceText}>Starting at $2,500</span><br />
                        Door Cups - <span style={priceText}>$25 per door</span><br />
                        Door Edge Guards - <span style={priceText}>$10 per door</span><br />
                    </p>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    </Layout>
)

export default PaintProtectionFilmServicesPage